/* SearchBar style */

#searchInput {
    text-align: left;
    height: 40px;
}

.searchIcon {
    cursor: pointer;
}

.searchContainer {
    display: flex;
    justify-content: center;
    width: 40px;
}

.inputContainer {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    background-color: white;
}

.inputContainer input:focus {
    outline: none;
}

@media (min-width: 769px) {
    #searchInput {
        width: calc(100vw - 265px);
    }
}

@media (max-width: 768px) {
    .searchBar {
        text-align: center;
    }
    #searchInput {
        width: 95%;
    }
}