/* TopBar style */

@media (max-width: 768px) {
    .topBar {
        position: fixed;
        top: 0;
        background-color: black;
        width: 100%;
        border-bottom: 2px solid;
        border-color: #333;
        height: 80px;
        font-family: franklinGothic;
        z-index: 1;
    }

    .logoHz {
        display: inline-block;
        padding-left: 3.75vw;
        padding-bottom: 1.5vh;
    }

    .navMenu {
        height: 2rem;
        width: 2rem;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        z-index: 10;
    }

    .bar {
        height: .25rem;
        width: 2rem;
        background-color: white;
        border-radius: 10px;
    }

    .HamburgerMenu {
        color: white;
        padding-top: 1.5em;
        padding-right: 5vw;
        cursor: pointer;
        float: right;
    }

    .topBarNav {
        display: flex;
        flex-direction: column;
        background-color: black;
        padding-top: 2vh;
        margin-top: -4.5vh;
        /* border-top: 1px solid #333; */
        color: beige;
        border-bottom: 1.5px solid #333;
    }

    .mobileLi {
        color: white;
        text-align: center;
        margin-bottom: 2vh;
    }

    .mobileLi:hover {
        cursor: pointer;
    }
    
    a.active {
        background-color: #232323;
        padding: 10px;
        display: inline;
        border-radius: 10px;
        color: beige;
    }
}