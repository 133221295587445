/* Archive style */

.indvArchive {
    margin: 1vw;
    cursor: pointer;
    /* color: beige; */
}

.indvArchive:hover {
    transform: scale(1.025);
    color: beige;
    transition: transform 0.5s ease;
}

@media (min-width: 769px) {
    
    .playIcon1 {
        display: inline-block;
        vertical-align: middle;
        opacity: 0; 
        transition: opacity 0.3s ease; 
    }
    
    .indvArchive:hover .playIcon1 {
        opacity: 1;
    }

/*     .indvArchive:hover {
        transform: scale(1.025);
        color: beige;
        transition: transform 0.5s ease;
    } */
}

@media (max-width: 768px) {
    .playIcon1 {
        display: none;
    }

/*     .indvArchive:hover {
        color: beige;
        transition: color 0.40s ease;
    } */
}

.hereLink {
    color: beige;
}

.allArchives {
    background-color: #333;
    padding-top: .25vh;
    padding-bottom: .5vh;
    padding-left: .5vw;
    padding-right: .25vw;
    border-radius: 15px;
}