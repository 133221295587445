/* LastEpisode style */

.archiveFile {
    width: 99%;
    height: 10vh;
}

.recentContent {
    height: 100%;
}

.lastDescription {
    margin-bottom: 15px;
}

.playlist {
    list-style: none;
    background-color: #333;
    padding-top: 2.5vh;
    padding-bottom: 1vh;
    border-radius: 15px;
}

.MRLi {
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    margin-bottom: 2vh;
}

.MRLi:Hover {
    transform: scale(1.025);
    /* border-right: 2px solid beige; */
    color: beige;
    transition: /* border-right 0.1s, */ transform 0.25s;
}

.downloadIcon {
    display: inline-block;
    vertical-align: middle;
    opacity: 0;
    transition: opacity 0.3s ease; 
}

.lastLink:hover .downloadIcon {
    opacity: 1;
}

.externalIcon {
    display: inline-block;
    vertical-align: middle;
    opacity: 0;
    padding-left: 5px;
    transition: opacity .3s ease;
}

.lastLink:hover .externalIcon {
    opacity: 1;
}

@media (max-width: 768px) {
    .downloadIcon {
        display: none;
    }
    .externalIcon {
        display: none;
    }
}