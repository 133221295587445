/* Playlists style */

@media (min-width: 769px) {
    .spinitronPlaylist {
        /* position: absolute; */
        /* width: calc(100vw - 250px); */
        width: 99%;
        height: calc(100vh - 150px);
    }
}

@media (max-width: 768px) {
    .spinitronPlaylist {
        height: calc(100vh - 74px);
        width: 99%;
    }
}