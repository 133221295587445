/* About style */

.tag {
    color: beige;
    font-family: cooperBlack;
}

.extLink {
    color: beige;
}

.aboutText {
    background-color: #333;
    padding: 10px;
    border-radius: 15px;
}

.heading {
    margin-top: 0;
    padding-top: 0;
}