.spin {
    background-color: #333;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.spinImg {
    border-radius: 10px;
}

.spinText p{
    margin: 5px;
}

.numText {
    display: inline-block;
    width: 30px;
    text-align: right;
    margin-right: 15px;
}

.artistText, .albumText {
    color: beige;
}

.numAndSpin {
    display: flex;
    align-items: center;
    margin-right: 5px;
}