/* App style */

@font-face {
    font-family: cooperBlack;
    src: url(./assets/cooperblackitalic.otf);
}

@font-face {
    font-family: franklinGothic;
    src: url(./assets/OPTIFranklinGothic-Medium.otf);
}

body {
    background-color: #232323;
}

.App {
    display: flex;
    font-family: franklinGothic;
}

.mainContent {
    color: white;
    font-family: franklinGothic;
}

h1 {
    font-family: cooperBlack;
    z-index: 1;
}

h2 {
    color: beige;
}

hr {
    height: 1px;
    background-color: #333;
    border: none;
}

li, h3 {
    font-family: franklinGothic;
}

/* ::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #232323;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
    background: beige;
} */

@media (min-width: 769px) {
    .mainContent {
        position: fixed;
        width: calc(100vw - 200px);
        height: 100%;
        margin-left: 200px;
        padding: 20px;
        box-sizing: border-box;
        overflow-y: auto;
        overflow-x: hidden;
    }

    h1 {
        position: sticky;
        margin-top: 0px;
        top: -21px;
        /* width: calc(100vw - 240px); */
        background: #232323;
        border-top: 0;
        padding-top: 15px;
        margin-left: -1px;
    }

    .headerHR {
        height: 2px;
    }
}

@media (max-width: 768px) {
    .App {
        flex-direction: column;
    }

    .mainContent {
        padding-top: calc(1vh + 70px);
        padding-left: 5vw;
        padding-right: 5vw;
    }
}