/* SignUp css */

.signUpClose {
    cursor: pointer;
}

.signUpLi {
    color: beige;
}

@media (min-width: 769px) {
    .signUp {
        position: fixed;
        width: calc(100vw - 200px);
        margin-left: 200px;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        color: white;
        text-align: center;
        backdrop-filter: blur(5px);
    }

    input[type="text"], input[type="email"], button {
        font-family: franklinGothic;
        text-align: center;
    }

    button {
        background-color: black;
        color: white;
    }

    .signUpText {
        position: relative;
        display: inline-block;
        background-color: black;
        border-radius: 10px;
        padding: 10px;
        top: 40%;
    }

    .question {
        font-family: cooperBlack;
    }

    .closeIcon {
        padding-top: 10px;
        /* cursor: pointer; */
    }

    .mobileBr {
        display: none;
    }
}

@media (max-width: 768px) {
    .signUp {
        position: fixed;
        top: 0;
        height: 100%;
        text-align: center;
        color: white;
        backdrop-filter: blur(5px);
        padding: 20px;
        z-index: 10;
    }

    .signUpText {
        position: relative;
        top: 40%;
        display: inline-block;
        background-color: black;
        border-radius: 10px;
        padding: 8px;
        margin: 10px;
        font-size: 85%;
    }
    
    input[type="text"], input[type="email"], button {
        font-size: 85%;
        font-family: franklinGothic;
        text-align: center;
    }

    button {
        background-color: black;
        margin-top: 5px;
        color: white;
    }

    .question {
        font-family: cooperBlack;
    } 

    .closeIcon {
        margin-top: 5px;
        /* cursor: pointer; */
    }
}