/* SideBar style */

.sideBar {
    height: 100%;
    width: 200px;
    background-color: #000000;
    color: white;
    position: fixed;
    bottom: 0;
    border-right: 2px solid;
    border-color: #333;
}

.logo {
    position: relative;
    text-align: center;
}

/* .sideBarLi {
    margin-bottom: 2vh;
} */

.signUpLi {
    color: beige;
}

.signUpLi:hover {
    transform: scale(1.025);
    border-right: 2px solid beige;
    transition: border-right 0.1s, transform 0.25s;
    cursor: pointer;
}

@media (min-width: 769px) {
    a.active {
        background-color: #232323;
        padding: 10px;
        display: block;
        border-radius: 10px 0 0 10px;
        border-right: 2px solid beige;
        color: beige;
    }
}

/* @media (max-width: 1224px) {
    .footer {
        display: none;
    }
}
 */

.navImg {
    width: 15px;
    padding-right: .25vw;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 5px;
}

.socials {
    text-align: center;
}

.footerImg {
    width: 40px;
    padding: 2px;
}

a {
    text-decoration: none;
    color: inherit;
}

.nav {
    margin-top: -.3vw;
    list-style: none;
    display: flex;
    flex-direction: column;
    font-size: 105%;
}

.sideBarLiDiv:hover {
    transform: scale(1.025);
    border-right: 2px solid beige;
    transition: border-right 0.1s, transform 0.25s;
    cursor: pointer;
}

.sideBarLiDiv {
    margin-bottom: 2vh;
}